import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const WorkoutCard = ({icon}) => {
  return (
    <button className="flex items-center justify-center text-white bg-white rounded-full bg-opacity-20" style={{width: 50, height: 50 }}>
      <span className="no-underline material-icons">{icon}</span>
    </button>
  )
}

export default WorkoutCard

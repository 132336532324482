import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);


export default async (workoutId) => {
  const stripe = await stripePromise;
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  try {
    const response = await fetch(`${process.env.GATSBY_API_URL}/createCheckoutSession?id=${workoutId}&host=${protocol}//${hostname}${port}`, {
      method: "POST",
    });
    const session = await response.json();
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      alert(result.error.message)
    }
  } catch(e) {
    alert(e);
  }
}

import React, { useState, useEffect } from 'react'
import redirectToCheckout from '../../api/redirect-to-checkout'
import './styles.scss'
import ButtonIcon from '../../components/button-icon'
import { Link } from 'gatsby'
// import amplitude from 'amplitude-js';


// markup
const WorkoutPage = (props) => {
  const workoutId = props.id
  const [workout, setWorkout] = useState({
    isLoaded: false,
    title: '',
    price: 0,
    exercises: []
  })
  const [isLoading, setIsLoading] = useState(false)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    const fetchWorkoutData = () => {
      setIsLoading(true)
      fetch(`${process.env.GATSBY_API_URL}/getWorkoutDetails?id=${workoutId}`)
        .then(response => response.json())
        .then(data => {
          setWorkout({ ...data, isLoaded: true })
          setIsLoading(false)
          // TODO #1
          // amplitude.getInstance().init('ef3d814cda85eee628655e98e75c558a', data.createdBy)
          // amplitude.getInstance().logEvent('Workout Viewed', {
          //   workoutId: data.id,
          //   createdBy: data.createdBy
          // })
        });
    }

    if (workout.isLoaded === false) {
      fetchWorkoutData()
    }

  }, []);

  const startPurchase = () => {
    redirectToCheckout(workoutId);
  }

  return (
    <main className="prose">
      <div className="cover">
        <img src={workout.coverImageURL} />
        <div className="top-nav">
          <Link to="/" className="no-underline">
            <ButtonIcon icon="arrow_back" onClick={() => alert('back')}/>
          </Link>
        </div>
        <div className="gradient" />
        <div className="details">
          <h3>{workout.level}</h3>
          <h1>{workout.title} {workout.trainer ? `(by ${workout.trainer.firstName})` : ''}</h1>
          {workout.price > 0 && <button className="px-8 py-2 font-bold text-black bg-white rounded-full" onClick={() => startPurchase()}>Buy ${workout.price}</button>}
        </div>
      </div>
      <p className="m-3">{workout.muscles && workout.muscles.map(muscle => {
        return (<span key={muscle} className="mr-2">{muscle}</span>)
      })}</p>
      <p className="m-3">{workout.description}</p>
    </main>
  )
}

export default WorkoutPage

// export const query = graphql`
// # $trainerId is pased via "context" in gatsby-node.js using exports.createPages
// query($id: String) {
//   workout(id: {eq: $id}) {
//     id
//     exercises
//     title
//     createdBy
//     price
//     trainer {
//       firstName
//     }
//   }
// }
// `